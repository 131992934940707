import React, {useEffect} from 'react'
import '@szhsin/react-menu/dist/index.css'
import styles from './raci.module.css'

const RACI = (props) => {
    const {roles, activities, handleRole, handleActivity, className, style} = props
    const size = 30
    const gridTemplateRows = activities.map(() => `${size}px`).join(' ')
    const gridTemplateColumns = `minmax(120px, auto) ${roles.map(() => `${size}px`).join(' ')}`
    let raci, header
    
    const mouseMoveHandler = (e) => {
        const columnIndex = roles.length - Math.ceil((raci.clientWidth + raci.offsetLeft - e.pageX ) / size)
        raci.querySelectorAll(`.${styles.column}`).forEach(column => {
            column.classList.toggle(styles.hover, +column.getAttribute('data-column') === columnIndex)
        })

        const rowIndex = Math.floor((e.pageY - header.clientHeight - raci.offsetTop + raci.scrollTop) / size)
        raci.querySelectorAll(`.${styles.row}`).forEach(row => {
            row.classList.toggle(styles.hover, +row.getAttribute('data-row') === rowIndex)
        })
    }

    const mouseLeaveHandler = (e) => {
        raci.querySelectorAll(`.${styles.column}`).forEach(column => {
            column.classList.remove(styles.hover)
        })

        raci.querySelectorAll(`.${styles.row}`).forEach(row => {
            row.classList.remove(styles.hover)
        })
    }

    activities.forEach((activity, i) => {
        activity.index = i
    })

    useEffect(() => {
        const target = raci
        
        const resizeObserver = new ResizeObserver(([entry]) => {
            target.querySelectorAll(`.${styles.activity} span`).forEach(activity => {
                activity.classList.toggle('tooltip', activity.scrollWidth > activity.offsetWidth)
            })      
        })  

        resizeObserver.observe(target)

        return () => {
            resizeObserver.unobserve(target)
        }
    }, [raci])

    return (
        <div ref={ref => raci = ref} className={`${className} ${styles.raci}`} style={{...style, minWidth: `${120 + roles.length * size}px`}} role='presentation' onMouseMove={mouseMoveHandler} onMouseLeave={mouseLeaveHandler}>
            <div ref={ref => header = ref} className={styles.header} style={{gridTemplateColumns, gridTemplateRows: `auto ${size}px`}}>
                <div className={styles.references} style={{gridColumn: 1, gridRowStart: 1, gridRowEnd: 3}}>
                    <div className={`tooltip ${styles.reference}`} data-tooltip={'Works to complete the task'}>
                        <div className={styles.pill}>R</div>
                        <span>Responsible</span>
                    </div>
                    <div className={`tooltip ${styles.reference}`} data-tooltip={'Decision maker and ultimately answerable for the correct completion of the task'}>
                        <div className={styles.pill}>A</div>
                        <span>Accountable</span>
                    </div>
                    <div className={`tooltip ${styles.reference}`} data-tooltip={'Provides expertise and guidance'}>
                        <div className={styles.pill}>C</div>
                        <span>Consulted</span>
                    </div>
                    <div className={`tooltip ${styles.reference}`} data-tooltip={'Kept up-to-date on progress'}>
                        <div className={styles.pill}>I</div>
                        <span>Informed</span>
                    </div>
                </div>
                {
                    roles.map(role => (
                        <div key={`name-${role.id}`} className={`${styles.role} ${styles[role.status]}`} style={{gridColumn: role.index + 2, gridRow: 1}} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleRole(role)}><span>{role.title}</span></div>
                    ))
                }
                {
                    roles.map(role => (
                        <div key={`acronym-${role.id}`} className={`${styles.acronym} ${styles[role.status]}`} style={{gridColumn: role.index + 2, gridRow: 2}} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleRole(role)}><span>{role.name}</span></div>
                    ))
                }
                {
                    roles.map(role => (
                        <div key={`column-${role.id}`} data-column={role.index} className={styles.column} style={{gridColumn: role.index + 2, gridRowStart: 1, gridRowEnd: -1}}/>
                    ))
                }
            </div>
            <div className={styles.rows} style={{gridTemplateColumns, gridTemplateRows}}>
                {
                    activities.map(activity => (
                        <div key={`activity-${activity.id}`} className={styles.activity} style={{gridColumn: 1, gridRow: activity.index + 1}} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleActivity(activity)}>
                            <span data-tooltip={activity.title}>{activity.title}</span>
                        </div>
                    ))
                }
                {
                    activities.map(activity => (
                        activity.responsibilities.map(responsibility => (
                            <div key={`responsibility-${responsibility.id}`} className={`${styles.responsibility} ${styles[responsibility.role.status]}`} style={{gridColumn: responsibility.role.index + 2, gridRow: responsibility.activity.index + 1}}>{responsibility.raci}</div>
                        ))
                    ))
                }
                {
                    activities.map(activity => (
                        <div key={`row-${activity.id}`} data-row={activity.index} className={styles.row} style={{gridColumnStart: 1, gridColumnEnd: -1, gridRow: activity.index + 1}}/>
                    ))
                }
                {
                    roles.map(role => (
                        <div key={`column-${role.id}`} data-column={role.index} className={styles.column} style={{gridColumn: role.index + 2, gridRowStart: 1, gridRowEnd: -1}}/>
                    ))
                }
            </div>  
        </div>
    )
}

export default RACI