import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import CareersGarden from '../components/careersGarden'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import './global.css'

const IndexPage = ({ data }) => {

  const [hasMounted, setHasMounted] = useState(false)

  const skills = data.skills.nodes.map(skill => ({
    id: skill.recordId,
    name: skill.data.Name,
    resources: skill.data.Resources
  }))

  const checklist = data.checklist.nodes.map(check => ({
    id: check.recordId,
    name: check.data.Check
  }))

  const tools = data.tools.nodes.map(tool => ({
    id: tool.recordId,
    name: tool.data.Name,
    url: tool.data.URL,
    description: tool.data.Description
  }))

  const resources = data.resources.nodes.map(resource => ({
    id: resource.recordId,
    name: resource.data.Name,
    url: resource.data.URL,
  }))

  const people = data.people.nodes.map(person => ({
    id: person.recordId,
    name: person.data.Display_name,
    photo: person.avatar?.publicURL,
    title: person.data.Title,
    roles: person.data.Roles || [],
    tla: person.data.TLA || person.recordId
  })).sort((a, b) => a.name.localeCompare(b.name))

  const roles = data.roles.nodes.map(role => ({
    id: role.recordId,
    name: role.data.Name,
    people: role.data.People,
    description: role.data.Description,
    responsibilities: role.data.Responsibilities || [],
    title: role.data.Title,
    aka: role.data.aka,
    area: role.data.area,
    skills: role.data.Skills
  })).sort((a, b) => a.title.localeCompare(b.title))

  const responsibilities = data.responsibilities.nodes.map(responsibility => ({
    id: responsibility.recordId,
    activity: responsibility.data.Activity,
    raci: responsibility.data.RACI,
    role: responsibility.data.Role
  }))

  const activities = data.activities.nodes.map(activity => ({
    id: activity.recordId,
    name: activity.data.Name,
    responsibilities: activity.data.Responsibilities || [],
    title: activity.data.Title,
    description: activity.data.Description,
    checklist: activity.data.Checklist,
    tools: activity.data.Tools
  })).sort((a, b) => a.title.localeCompare(b.title))

  const dictionary = {
    people: people.reduce((accumulator, person) => ({ ...accumulator, [person.id]: person }), {}),
    roles: roles.reduce((accumulator, role) => ({ ...accumulator, [role.id]: role }), {}),
    responsibilities: responsibilities.reduce((accumulator, responsibility) => ({ ...accumulator, [responsibility.id]: responsibility }), {}),
    activities: activities.reduce((accumulator, activity) => ({ ...accumulator, [activity.id]: activity }), {}),
    skills: skills.reduce((accumulator, skill) => ({ ...accumulator, [skill.id]: skill }), {}),
    checklist: checklist.reduce((accumulator, check) => ({ ...accumulator, [check.id]: check }), {}),
    tools: tools.reduce((accumulator, tool) => ({ ...accumulator, [tool.id]: tool }), {}),
    resources: resources.reduce((accumulator, resource) => ({ ...accumulator, [resource.id]: resource }), {})
  }

  skills.forEach(skill => {
    skill.resources = (skill.resources || []).map(id => dictionary.resources[id])
  })

  people.forEach(person => {
    person.roles = (person.roles || []).map(id => dictionary.roles[id])
  })

  roles.forEach((role, i) => {
    role.people = (role.people || []).map(id => dictionary.people[id]).sort((a, b) => a.name.localeCompare(b.name))
    role.responsibilities = (role.responsibilities || []).map(id => dictionary.responsibilities[id])
    role.skills = (role.skills || []).map(id => dictionary.skills[id])
    role.index = i
  })

  responsibilities.forEach(responsibility => {
    responsibility.role = dictionary.roles[responsibility.role]
    responsibility.activity = dictionary.activities[responsibility.activity]
  })

  activities.forEach(activity => {
    activity.responsibilities = (activity.responsibilities || []).map(id => dictionary.responsibilities[id])
    activity.checklist = (activity.checklist || []).map(id => {
      return dictionary.checklist[id]
    })
    activity.tools = (activity.tools || []).map(id => dictionary.tools[id])
  })

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <>
      <SEO title='Careers garden' description='See what accountabilities each role has, how they interact with other roles and who is able to perform different roles.' />
      <Router basepath='/'>
        {
          hasMounted? <CareersGarden path='/' people={people} roles={roles} activities={activities} responsibilities={responsibilities} /> : null
        }
      </Router>
    </>
  )
}

export const query = graphql`
query {
  roles: allAirtableRoles {
    nodes {
      data {
        area
        aka
        Responsibilities
        Name
        Description
        Title
        People
        Skills
      }
      recordId
    }
  },
  responsibilities: allAirtableResponsibilities {
    nodes {
      data {
        Activity
        Name
        RACI
        Role
      }
      recordId
    }
  },
  activities: allAirtableActivities {
    nodes {
      data {
        Name
        Responsibilities
        Title
        Description
        Checklist
        Tools
      }
      recordId
    }
  },
  checklist: allAirtableChecklist {
    nodes {
      data {
        Check
      }
      recordId
    }
  },
  skills: allAirtableSkills {
    nodes {
      data {
        Name
        Resources
      }
      recordId
    }
  },
  tools: allAirtableTools {
    nodes {
      data {
        Description
        Name
        URL
      }
      recordId
    }
  },
  people: allAirtablePeople {
    nodes {
      avatar {
        publicURL
      }
      data {
        Display_name
        Roles
        TLA
        Title
      }
      recordId
    }
  },
  resources: allAirtableResources {
    nodes {
      data {
        Name
        URL
      }
      recordId
    }
  }
}`

export default IndexPage
