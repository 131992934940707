import React from 'react'
import styles from './detail.module.css'
import CloseIcon from '../images/icons/close.svg'

const Detail = (props) => {

    const {roles, currentPerson, currentArea, currentRole, currentActivity, className, handlePerson, handleRole, handleClear, handleActivity} = props
    let people, content = null
    if(currentPerson) {
        content = (
            <div className={`${className} ${styles.detail}`}>
                <div className={styles.header}>
                    <div className={styles.name}>{currentPerson.name}</div>
                    <div className={styles.title}>{currentPerson.title}</div>
                    <div className={styles.close} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleClear(true)}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className={styles.avatar} style={currentPerson?.photo? {backgroundImage: `url(${currentPerson?.photo})`} : null}/>
                <div className={styles.label}>Roles</div>
                <div className={styles.roles}>
                    {
                        currentPerson.roles.sort((a, b) => a.title.localeCompare(b.title)).map(role => (
                            <div key={role.id} className={styles.role} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleRole(role)}>
                                <img width='24px' height='24px' src={`/images/roles/${role.name}.svg`} alt={role.name}/>
                                <span>{role.title}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    } else if(currentArea) {
        const relatedRoles = roles.filter(role => role.area === currentArea)
        people = [...new Set (relatedRoles.map(role => role.people).flat())].filter(person => person)
        content = (
            <div className={`${className} ${styles.detail}`}>
                <div className={styles.header}>
                    <div className={styles.name}>
                        <span>{currentArea}</span>
                    </div>
                    <div className={styles.close} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleClear(true)}>
                        <CloseIcon/>
                    </div>
                </div>
                {
                    people.length > 0 && (
                        <div className={styles.people}>
                        {
                            people.map(person => (
                                <div key={person?.id} className={`tooltip ${styles.person}`} data-tooltip={person?.name} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handlePerson(person, true)} aria-label='person' style={{backgroundImage: `url(${person?.photo})`}}/>
                            ))
                        }
                        </div>
                    )
                }
                <div className={styles.label}>Roles</div>
                <div className={styles.roles}>
                    {
                        relatedRoles.map(role => (
                            <div key={role.id} className={styles.role} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleRole(role)}>
                                <img width='24px' height='24px' src={`/images/roles/${role.name}.svg`} alt={role.name}/>
                                <span>{role.title}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    } else if(currentRole) {
        people = currentRole.people.filter(person => person)
        content = (
            <div className={`${className} ${styles.detail}`}>
                <div className={styles.header}>
                    <img className={styles.icon} width='24px' height='24px' src={`/images/roles/${currentRole.name}.svg`} alt={currentRole.name} />
                    <div className={styles.area}>{currentRole.area}</div>
                    <div className={styles.name}>
                        <span>{currentRole.title}</span>
                        <div className={styles.acronym}>{currentRole.name}</div>
                    </div>
                    {
                        currentRole.aka && (
                            <div className={styles.title}>{currentRole.title}</div>
                        )
                    }
                    <div className={styles.close} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleClear(true)}>
                        <CloseIcon/>
                    </div>
                </div>
                {
                    currentRole.description && (
                        <div className={styles.description}>{currentRole.description}</div>
                    )
                }
                {
                    people.length > 0 && (
                        <div className={styles.people}>
                        {
                            people.map(person => (
                                <div key={person.id} className={`tooltip ${styles.person}`} data-tooltip={person?.name} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handlePerson(person, true)} aria-label='person' style={{backgroundImage: `url(${person?.photo})`}}/>
                            ))
                        }
                        </div>
                    )
                }
                <div className={styles.label}>Responsibilities</div>
                <ul className={styles.responsibilities}>
                    {
                        currentRole.responsibilities.sort((a, b) => a.activity.title.localeCompare(b.activity.title)).map(responsibility => (
                            <li key={responsibility.id}>
                                <div className={styles.responsibility}>
                                    <span role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleActivity(responsibility.activity)}>{responsibility.activity.title}</span>
                                    <div className={styles.raci}>{responsibility.raci}</div>
                                    {
                                        responsibility.activity.responsibilities.filter(responsibility => responsibility.role !== currentRole).map(responsibility => (
                                            <div key={responsibility.id} className={`tooltip ${styles.acronym}`} data-tooltip={responsibility.role.title} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleRole(responsibility.role)}>{responsibility.role.name}</div>
                                        ))
                                    }
                                </div>
                            </li>
                        ))
                    }
                </ul>
                {
                    currentRole.skills.length > 0 && (
                        <>
                            <div className={styles.label}>Skills</div>
                            <ul className={styles.skills}>
                            {
                                currentRole.skills.sort((a, b) => a.name.localeCompare(b.name)).map(skill => (
                                    <li key={skill.id}>
                                        <span>{skill.name}</span>
                                        {
                                            skill.resources.length > 0 && (
                                                <ul>
                                                    {
                                                        skill.resources.sort((a, b) => a.name.localeCompare(b.name)).map(resource => (
                                                            <li key={resource.id}>
                                                                <a href={resource.url} target='_blank' rel='noreferrer'>{resource.name}</a>
                                                            </li>

                                                        ))
                                                    }
                                                </ul>
                                            )
                                        }
                                    </li>
                                ))
                            }
                            </ul>
                        </>
                    )
                }
            </div>
        )
    } else if(currentActivity) {
        people = [...new Set (currentActivity.responsibilities.map(responsibility => responsibility.role.people).flat())].filter(person => person)
        content = (
            <div className={`${className} ${styles.detail}`}>
                <div className={styles.header}>
                    <div className={styles.name}>
                        <span>{currentActivity.title}</span>
                    </div>
                    <div className={styles.close} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleClear(true)}>
                        <CloseIcon/>
                    </div>
                </div>
                {
                    currentActivity.description && (
                        <div className={styles.description}>
                            {currentActivity.description}
                        </div>
                    )
                }
                {
                    people.length > 1 && (
                        <div className={styles.people}>
                            {
                                people.map((person, i) => (
                                    <div key={i} className={`tooltip ${styles.person}`} data-tooltip={person?.name} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handlePerson(person)} aria-label='person' style={{backgroundImage: `url(${person?.photo})`}}/>
                                ))
                            }
                        </div>

                    )
                    
                }
                <div className={styles.label}>Roles</div>
                <div className={styles.roles}>
                    {
                        currentActivity.responsibilities.sort((a, b) => a.role.title.localeCompare(b.role.title)).map(responsibility => (
                            <div key={responsibility.id} className={styles.roleContainer}>
                                <div className={styles.role} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleRole(responsibility.role)}>
                                    <img width='24px' height='24px' src={`/images/roles/${responsibility.role.name}.svg`} alt={responsibility.role.name}/>
                                    <span>{responsibility.role.title}</span>
                                </div>
                                <div className={styles.raci}>{responsibility.raci}</div>
                            </div>
                        ))
                    }
                </div>
                {
                    currentActivity.checklist.length > 0 && (
                        <>
                            <div className={styles.label}>Checklist</div>
                            <ul className={styles.checklist}>
                                {
                                    currentActivity.checklist.sort((a, b) => a.name.localeCompare(b.name)).map(check => (
                                        <li key={check.id}>{check.name}</li>
                                    ))
                                }
                            </ul>
                        </>
                    )
                }
                {
                    currentActivity.tools.length > 0 && (
                        <>
                            <div className={styles.label}>Tools</div>
                            <ul className={styles.tools}>
                                {
                                    currentActivity.tools.sort((a, b) => a.name.localeCompare(b.name)).map(tool => (
                                        <li key={tool.id}>
                                            <a href={tool.url} target='_blank' rel='noreferrer'>{tool.name}</a>
                                            {
                                                tool.description && (
                                                    <p>{tool.description}</p>
                                                )
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </>
                    )
                }
            </div>
        )
    }
    
    return content
}

export default Detail