import React, {useState, useEffect} from 'react'
import {Menu, MenuItem, MenuButton, SubMenu} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import styles from './peopleList.module.css'
import SearchIcon from '../images/icons/search.svg'
import CloseIcon from '../images/icons/close.svg'
import FilterIcon from '../images/icons/filter.svg'

const PeopleList = (props) => {
    const {people, areas, roles, activities, currentPerson, currentArea, currentRole, currentActivity, handlePerson, handleArea, handleRole, handleActivity, handleClear, className} = props
    const [search, setSearch] = useState('')
    let list

    const filteredPeople = people
        .filter(person => person)
        .filter(person => person?.name.toLowerCase().includes(search.toLowerCase()))
        .filter(person => currentArea === undefined || person.roles.some(role => role.area === currentArea))
        .filter(person => currentRole === undefined || person.roles.includes(currentRole))
        .filter(person => currentActivity === undefined || person.roles.some(role => role.responsibilities.some(responsibility => responsibility.activity === currentActivity)))

    const keyHandler = (e) => {
        let increment = 0
        switch(e.key) {
            case 'ArrowDown':
                increment = 1
                break
            case 'ArrowUp':
                increment = -1
                break
            case 'Escape':
                setSearch('')
                break
            default:
        }
        if(increment) {
            e.preventDefault()
            let index = filteredPeople.findIndex(person => person === currentPerson) + increment
            if(index < 0) {
                index = filteredPeople.length - 1
            }
            if(index >= filteredPeople.length) {
                index = 0
            }
            handlePerson(filteredPeople[index])
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyHandler)
        return () => {
            document.removeEventListener('keydown', keyHandler)
        }
    })

    useEffect(() => {
        if(currentPerson && currentPerson.ref) {
            if(currentPerson.ref.offsetTop < list.scrollTop) {
                currentPerson.ref.scrollIntoView({block: 'start'})
            } else if (currentPerson.ref.offsetTop + currentPerson.ref.clientHeight > list.scrollTop + list.clientHeight) {
                currentPerson.ref.scrollIntoView({block: 'end'})
            }
        }
    }, [currentPerson, list])

    return (
        <div className={`${className} ${styles.peopleList}`}>
            <div className={styles.header}>
                <div className={styles.search}>
                    <SearchIcon className={styles.searchIcon}></SearchIcon>
                    <input type='text' placeholder='Search' onChange={e => setSearch(e.target.value)} onKeyDown={e => e.key === 'Escape'? setSearch('') : null} value={search}></input>
                    <CloseIcon className={styles.clearIcon} style={{visibility: search.length > 0? 'visible' : 'hidden'}} onClick={() => setSearch('')}/>
                </div>
                <Menu menuButton={<MenuButton className={`${styles.filterButton} ${currentArea || currentRole || currentActivity? styles.active : ''}`}><FilterIcon/></MenuButton>} align='start' offsetX={12} offsetY={0} arrow={false} position='anchor' viewScroll='close' key='right' direction='right'>
                    <MenuItem onClick={() => handleClear()}>Clear filter</MenuItem>
                    <SubMenu label='Areas' overflow='auto'>
                        {
                            areas.map((area, i) => <MenuItem key={i} value={area} onClick={d => handleArea(d.value)}>{area}</MenuItem>)
                        }
                    </SubMenu>
                    <SubMenu label='Roles' overflow='auto'>
                        {
                            roles.map((role, i) => <MenuItem key={i} value={role} onClick={d => handleRole(d.value)}>{role.title}</MenuItem>)
                        }
                    </SubMenu>
                    <SubMenu label='Activities' overflow='auto'>
                        {
                            activities.map((activity, i) => <MenuItem key={i} value={activity} onClick={d => handleActivity(d.value)}>{activity.title}</MenuItem>)
                        }
                    </SubMenu>
                </Menu>
                
            </div>
            <div ref={ref => list = ref} className={styles.list}>
                {filteredPeople.length?
                    filteredPeople.map(person => (
                        <div key={person?.name} ref={ref => person.ref = ref } className={`${styles.person} ${currentPerson && person === currentPerson? styles.currentPerson : ''}`} role='button' tabIndex={0} onKeyDown={() => {}} onClick={e => handlePerson(person)}>
                            <div className={styles.avatar} style={person?.photo? {backgroundImage: `url(${person?.photo})`} : null}/>
                            <span className={styles.name}>{person?.name}</span>
                        </div>
                    )) : <div className={styles.empty}>No matching people for {search? `'${search}'` : currentArea? `area '${currentArea}'` : currentRole? `role '${(roles.find(role => role === currentRole) || {}).title}'` : `activity '${(activities.find(activity => activity === currentActivity) || {}).title}'`}</div>
                }
            </div>
        </div>
    )
}

export default PeopleList