import React from 'react'
import { useQueryParams, useQueryParam, StringParam } from 'use-query-params'
import PeopleList from './peopleList'
import RACI from './raci'
import Detail from './detail'
import Garden from './garden'
import Tooltip from './tooltip'
import styles from "./careersGarden.module.css"
import Logo from '../images/m-white.svg'
import MapIcon from '../images/icons/map.svg'
import TableIcon from '../images/icons/table.svg'

const CareersGarden = (props) => {

    const {people, roles, activities, responsibilities} = props
    const areas = [...new Set(roles.map(role => role.area))].filter(area => area).sort()

    const [, setQuery] = useQueryParams({
        view: StringParam,
        person: StringParam,
        area: StringParam,
        role: StringParam,
        activity: StringParam,
    })

    const [viewParam, setViewParam] = useQueryParam('view', StringParam)
    const [personParam, setPersonParam] = useQueryParam('person', StringParam)
    const [areaParam, setAreaParam] = useQueryParam('area', StringParam)
    const [roleParam, setRoleParam] = useQueryParam('role', StringParam)
    const [activityParam, setActivityParam] = useQueryParam('activity', StringParam)

    const view = ['raci', 'garden'].find(view => view === viewParam) || 'raci'
    if(!viewParam || view !== viewParam) {
        setViewParam(view, 'replaceIn')
    }

    const person = people.find(person => person.tla === personParam || person.id === personParam)
    if(personParam && person === undefined) {
        setPersonParam(undefined, 'replaceIn')
    }

    const area = areas.find(area => area === areaParam)
    if(areaParam && area === undefined) {
        setAreaParam(undefined, 'replaceIn')
    }

    const role = roles.find(role => role.name === roleParam)
    if(roleParam && role === undefined) {
        setRoleParam(undefined, 'replaceIn')
    }

    const activity = activities.find(activity => activity.name === activityParam)
    if(activity && activity === undefined) {
        setActivityParam(undefined, 'replaceIn')
    }

    const handleView = (view) => {
        setQuery({
            view
        })
    }

    const handlePerson = (person, clear) => {
        setQuery({
            person: person? person.tla || person.id : undefined,
            area: clear? undefined : area? area : undefined,
            role: clear? undefined : role? role.name : undefined,
            activity: clear? undefined : activity? activity.name : undefined            
        })
    }

    const handleArea = (area) => {
        setQuery({
            person: undefined,
            area: area? area : undefined,
            role: undefined,
            activity: undefined
        })
    }

    const handleRole = (role) => {
        setQuery({
            person: undefined,
            area: undefined,
            role: role? role.name : undefined,
            activity: undefined
        })
    }

    const handleActivity = (activity) => {
        setQuery({
            person: undefined,
            area: undefined,
            role: undefined,
            activity: activity? activity.name : undefined
        })
    }

    const handleClear = (all = false) => {
        setQuery({
            person: all? undefined : person? person.tla || person.id : undefined,
            area: undefined,
            role: undefined,
            activity: undefined
        })
    }

    let relatedActivities
    if(person) {
        relatedActivities = activities.filter(activity => activity.responsibilities.some(responsibility => person.roles.includes(responsibility.role)))
        roles.forEach(d => {
            d.status = person.roles.includes(d)? 'selected' : relatedActivities.some(activity => activity.responsibilities.some(responsibility => responsibility.role === d))? 'related' : 'disabled'
        })
    } else if(area) {
        relatedActivities = activities.filter(activity => activity.responsibilities.some(responsibility => responsibility.role.area === area))
        roles.forEach(d => {
            d.status = d.area === area? 'selected' : relatedActivities.some(activity => activity.responsibilities.some(responsibility => responsibility.role === d))? 'related' : 'disabled'
        })
    } else if(role) {
        relatedActivities = activities.filter(activity => activity.responsibilities.some(responsibility => responsibility.role === role))
        roles.forEach(d => {
            d.status = d === role? 'selected' : relatedActivities.some(activity => activity.responsibilities.some(responsibility => responsibility.role === d))? 'related' : 'disabled'
        })
    } else if(activity) {
        relatedActivities = [activity]
        roles.forEach(d => {
            d.status = activity.responsibilities.some(responsibility => responsibility.role === d)? 'related' : 'disabled'
        })
    } else {
        relatedActivities = [...activities]
        roles.forEach(role => {
            role.status = 'related'
        })
    }

    return (
        <div className={styles.careersGarden}>
            <div className={styles.header}>
                <div className={styles.card}>
                    <Logo width="36px" height="36px"/>
                    <span>Careers Garden</span>
                    <div className={styles.viewSelector} role='button' tabIndex={0} onKeyDown={() => {}} onClick={() => handleView(view === 'garden'? 'raci' : 'garden')}>
                        {
                            view === 'garden'? <MapIcon/> : <TableIcon/>
                        }
                    </div>
                </div>
            </div>
            <PeopleList people={people} areas={areas} roles={roles} activities={activities} currentPerson={person} currentArea={area} currentRole={role} currentActivity={activity} handlePerson={handlePerson} handleArea={handleArea} handleRole={handleRole} handleActivity={handleActivity} handleClear={handleClear} className={styles.peopleList}/>
            <RACI roles={roles} activities={relatedActivities} handleRole={handleRole} handleActivity={handleActivity} className={`${styles.raci} ${view !== 'raci'? styles.hidden : ''}`}/>
            <Garden roles={roles} activities={activities} responsibilities={responsibilities} currentPerson={person} currentArea={area} currentRole={role} currentActivity={activity} handleRole={handleRole} handleActivity={handleActivity} className={`${styles.garden} ${view !== 'garden'? styles.hidden : ''}`}/>
            <Detail roles={roles} currentPerson={person} currentArea={area} currentRole={role} currentActivity={activity} handlePerson={handlePerson} handleArea={handleArea} handleRole={handleRole} handleActivity={handleActivity} handleClear={handleClear} className={styles.detail}/>
            <Tooltip/> 
        </div>
    )
}

export default CareersGarden