import React, {useEffect, useRef} from 'react'
import styles from './tooltip.module.css'

const Tooltip = () => {

    const tooltip = useRef()

    useEffect(() => {

        let timer

        const handleMouseOver =(e) => {
            if(e.target.classList.contains('tooltip')) {
                tooltip.current.textContent = e.target.getAttribute('data-tooltip')
                timer = setTimeout(() => {
                    const offset = 6
                    const targetBounds = e.target.getBoundingClientRect()
                    const tooltipBounds = tooltip.current.getBoundingClientRect()
                    const x = Math.max(offset, Math.min(window.innerWidth - tooltipBounds.width - offset, targetBounds.left + targetBounds.width / 2 - tooltipBounds.width / 2)) + window.scrollX
                    let y = targetBounds.top - tooltipBounds.height - offset + window.scrollY
                    if (y < window.scrollY) {
                        y = targetBounds.bottom + offset + window.scrollY
                    }
                    tooltip.current.style.left = `${x}px`
                    tooltip.current.style.top = `${y}px`
                    tooltip.current.classList.add(styles.visible)
                }, 500)            
            }
        }
    
        const handleMouseOut = (e) => {
            if(e.target.classList.contains('tooltip')) {
                tooltip.current.classList.remove(styles.visible)
                clearTimeout(timer)
            }
        }

        document.addEventListener('mouseover', handleMouseOver)
        document.addEventListener('mouseout', handleMouseOut)
        document.addEventListener('click', handleMouseOut)
        
        return () => {
            document.removeEventListener('mouseover', handleMouseOver)
            document.removeEventListener('mouseout', handleMouseOut)
        }

      }, [])

    return <div className={styles.tooltipComponent} ref={tooltip}/>
}

export default Tooltip
